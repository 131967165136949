import '@babel/polyfill';
import { registerApplication, start } from 'single-spa';


registerApplication(
  'header',
  () => import('./src/components/header/main.header.js'),
  () => true
);

registerApplication(
  'sidenav',
  () => import('./src/components/sidenav/main.sidenav.js'),
  () => true
);

registerApplication(
  'footer',
  () => import('./src/components/footer/main.footer.js'),
  () => true
);


registerApplication(
  'home',
  () => import('./src/projects/home/main.app.js'),
  () =>
    location.pathname.split('/')[1] === '' ||
      location.pathname.split('/')[1] === 'login' ||
      location.pathname.split('/')[1] === 'signup' ||
      location.pathname.split('/')[1] === 'inactive'
      ? true : false
);
registerApplication(
  'sim',
  () => import('./src/projects/sim/main.app.js'),
  () => location.pathname.split('/')[1] === 'sim' ? true : false
);
registerApplication(
  'ganttchart',
  () => import('./src/projects/ganttchart/main.app.js'),
  () => location.pathname.split('/')[1] === 'ganttchart' ? true : false
);

start();